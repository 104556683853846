<template>
  <div class="machining">

    <light-box :images="images" :index="index" @close="index = null" />

    <div class="page-title">
      <div class="content-container">
        <h1 class="title is-4">
          Machining
        </h1>
      </div>
    </div>

    <div class="content-container page-text">
      <div class="columns">
        <div class="column">

          <p>All of our machining is undertaken in our Albury based workshop with the aid of the latest technology CNC controlled lathes and mills.</p>
          <p>Our machine shop capabilities include:</p>
          <div class="content">
            <ul>
              <li>4 axis CNC milling</li>
              <li>CNC turning</li>
              <li>Turning</li>
              <li>Milling</li>
              <li>Slotting</li>
              <li>MasterCam 3D CadCam design and programming</li>
            </ul>
          </div>
        </div>

        <div class="column">
          <div class="pad shadow">
            <b-carousel
              :autoplay="true"
              :indicator="false"
              :pause-hover="false"
              :arrow="true"
              icon-pack="far"
              icon-next="chevron-right"
              :arrow-hover="false">
               <b-carousel-item v-for="(image, i) in images" :key="i">
                <a class="image is-4by3" @click="index = i">
                  <img class="bg-fff" :src="image">
                </a>
              </b-carousel-item>
            </b-carousel>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      index: null,
      images: [
        require('@/assets/img/machining/1.jpg'),
        require('@/assets/img/machining/2.jpg'),
        require('@/assets/img/machining/3.jpg'),
        require('@/assets/img/machining/4.jpg'),
        require('@/assets/img/machining/5.jpeg'),
        require('@/assets/img/machining/6.jpeg'),
        require('@/assets/img/machining/7.jpg'),
        require('@/assets/img/machining/8.jpg'),
        require('@/assets/img/machining/9.jpg'),
        require('@/assets/img/machining/10.png'),
        require('@/assets/img/machining/11.png')
      ]
    }
  }
}
</script>
